<template>
  <div id="app">
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <main id="main" v-if="!isLoading">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>

export default {
  name: "App",
  data: () => {
    return {
      isLoading: false,
      isFullPage: true,
    };
  },

  methods: {
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 3 * 1000);
    },
  },

  mounted() {
    this.openLoading();
  },
};
</script>

<style scoped>
</style>
