<template>
  <div>
    <div v-if="$isMobile()">
      <mobile></mobile>
    </div>
    <div v-else>
      <desktop></desktop>
    </div>
  </div>
</template>

<script>
import Desktop from "../views/Home";
import Mobile from "../views/HomeMobile";

export default {
  name: "App",
  data: () => {
    return {};
  },

  components: {
    Desktop,
    Mobile,
  },

  methods: {},

  mounted() {},
};
</script>

<style scoped>
</style>
