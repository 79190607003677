import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import titleMixin from "./mixins/titleMixin";
import vueScrollto from "vue-scrollto";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Buefy);
Vue.use(vueScrollto);
Vue.mixin(titleMixin);

import "animate.css";
import "hover.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./scss/style.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
Vue.use(VuePlyr);

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

Vue.config.productionTip = false;

window.axios = require("axios");

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
