<template>
  <div id="bellanova">
    <b-navbar centered spaced>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="../assets/logo.webp" alt="Bellanova Studio" />
        </b-navbar-item>
      </template>

      <template slot="start">
        <b-navbar-item v-scroll-to="'#hero_bg'" @click="hideSection">
          Inicio
        </b-navbar-item>
        <b-navbar-item @click="showAbout = !showAbout">
          Nosotras
        </b-navbar-item>
        <b-navbar-item v-scroll-to="'#benefits'" @click="hideSection">
          Beneficios
        </b-navbar-item>
        <b-navbar-item v-scroll-to="'#contact'" @click="hideSection">
          Contacto
        </b-navbar-item>
      </template>
    </b-navbar>

    <transition name="slide">
      <section id="about" v-if="showAbout">
        <div class="columns is-centered is-vcentered is-desktop">
          <div class="column is-6" id="pink_slot">
            <div id="about_video" class="p-4">
              <vue-plyr
                ref="plyr"
                autoplay
                loop
                muted
                :options="playerOptions"
                class="player"
              >
                <video src="../assets/espacio_bellanova.mp4">
                  <source
                    src="../assets/espacio_bellanova.mp4"
                    type="video/mp4"
                  />
                </video>
              </vue-plyr>
            </div>
          </div>
          <div class="column is-5">
            <div id="about_text" class="p-6">
              <h1 class="title">Nosotras</h1>
              <p class="has-text-centered">
                <span class="has-text-weight-bold">
                  Bellanova es un Studio ubicado en el eje cafetero, cuenta con
                  una de las mejores instalaciones e infraestructura en la
                  ciudad de Armenia.
                </span>
                Nace como un proyecto enfocado a mujeres que desean ser
                profesionales en el mundo del modelaje webcam.
              </p>

              <p class="pt-5 has-text-centered">
                Se destaca la feminidad, delicadeza y sensualidad de cada una de
                sus integrantes, respetando su estilo a través de un trabajo en
                equipo con el que logra fomentar lo mejor de cada quien. Para
                ello cuenta con un grupo de profesionales experimentados que
                ayudan como medio para alcanzar los objetivos que la modelo se
                haya propuesto.
              </p>
            </div>
          </div>
        </div>
      </section>
    </transition>

    <section v-if="!showAbout" class="border-top">
      <section class="hero" id="hero_bg">
        <vue-plyr ref="player" :options="playerOptions" class="shadow">
          <video autoplay muted loop id="myVideo">
            <source src="../assets/herovideo.mp4" type="video/mp4" />
          </video>
        </vue-plyr>
      </section>

      <section id="benefits" class="container-fluid">
        <div class="columns is-gapless is-vcentered is-centered is-desktop">
          <div class="column is-half has-text-centered">
            <div id="bg_bellanova">
              <h1 class="title underline pl-6">Beneficios</h1>
              <p class="p-6">
                En el momento en que inicias a formar parte de Bellanova cuentas
                no solo con una nueva familia que te acoge y te brinda todo el
                apoyo emocional que necesitas, sino que también te hace
                partícipe del crecimiento de la empresa como tal, por ende te
                ofrece beneficios.
              </p>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div id="img_bg"></div>
          </div>
          <div class="column is-one-quarter">
            <div id="img_pool"></div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="columns is-vcentered is-gapless is-desktop">
          <div class="column is-4">
            <div id="video_bg" class="pt-3">
              <div class="columns is-gapless is-multiline">
                <div class="column is-12">
                  <div id="promo_video">
                    <vue-plyr
                      ref="plyr"
                      autoplay
                      muted
                      :options="playerOptions"
                      class="player"
                    >
                      <video
                        width="400"
                        height="475"
                        src="../assets/bellanova_promo.mp4"
                      >
                        <source
                          src="../assets/bellanova_promo.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </vue-plyr>
                  </div>
                </div>
                <div class="column is-12 mt-6">
                  <h1 class="title underline is-pulled-right mr-6 pl-6">
                    Únete
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div id="lollipop_bg"></div>
          </div>
          <div class="column">
            <div id="face_bg"></div>
          </div>
          <div class="column">
            <div id="coffee_bg"></div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="columns is-vcentered is-centered is-desktop">
          <div class="column has-text-centered is-half">
            <h1 class="title underline">Acompañamiento</h1>
            <ul>
              <li class="py-1">
                Ayuda en la creación y acreditación de tu performance.
              </li>
              <li class="py-1">
                Sesiones de maquillaje, fotografía y expresión corporal.
              </li>
              <li class="py-1">
                Equipo de profesionales que te ayudan en cada área..
              </li>
              <li class="py-1">
                Capacitaciones que ayudan a mejorar tu rendimiento.
              </li>
              <li class="py-1">
                Apoyo y acompañamiento del único Studio VIP del Eje cafetero
                TREND STUDIOS.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="contact" class="container-fluid">
        <div class="columns is-desktop">
          <div class="column is-12">
            <div id="contact_bg" class="section">
              <div class="columns">
                <div class="column">
                  <div class="px-4">
                    <h1 class="title underline">Contacto</h1>
                    <div id="contact_video">
                      <vue-plyr
                        ref="plyr"
                        :options="playerOptions"
                        class="shadow"
                      >
                        <video
                          width="100%"
                          height="100%"
                          loop
                          autoplay
                          muted
                          src="../assets/bellanova_entreperneur.mp4"
                        >
                          <source
                            src="../assets/bellanova_entreperneur.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </vue-plyr>
                    </div>
                  </div>
                </div>
                <div class="column has-text-centered is-6">
                  <iframe
                    src="https://snapwidget.com/embed/889987"
                    class="snapwidget-widget"
                    allowtransparency="true"
                    frameborder="0"
                    scrolling="no"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 750px;
                      height: 625px;
                    "
                  ></iframe>

                  <b-button
                    tag="a"
                    href="https://www.instagram.com/bellanova_studios/"
                    target="_blank"
                  >
                    <i class="fas fa-external-link-alt"></i> Mostrar más
                  </b-button>
                </div>
                <div class="column">
                  <div class="columns is-multiline is-desktop">
                    <div class="column is-6">
                      <span id="social_media">
                        <a href="https://www.instagram.com/bellanova_studios/" target="_blank">
                          <i class="fab fa-instagram"></i>
                        </a>
                        <a
                          href="https://www.facebook.com/studiosbellanova"
                          class="px-5" target="_blank"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://twitter.com/bellanovaglam" target="_blank">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </span>
                    </div>
                    <div class="column is-10">
                      <a href="mailto:contacto@estudiobellanova.com" id="email"
                        >contacto@estudiobellanova.com</a
                      ><br />
                      <a href="tel:313 6183662" id="phone">Tel: 313 6183662</a
                      ><br />
                      <span>Armenia - Quindío</span>
                    </div>
                    <div class="column is-12 pt-4">
                      <p>
                        Abrimos de Lunes a Sábado
                        <br />
                        jornadas en la mañana, tarde y noche.
                      </p>
                    </div>
                    <div class="column is-12 pt-6 mt-5">
                      <form>
                        <b-field grouped>
                          <b-field expanded>
                            <b-input
                              placeholder="Nombre"
                              v-model="formData.name"
                            ></b-input>
                          </b-field>
                          <b-field expanded>
                            <b-input
                              placeholder="Email"
                              v-model="formData.email"
                            ></b-input>
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-input
                            placeholder="Asunto"
                            v-model="formData.subject"
                          ></b-input>
                        </b-field>
                        <b-field>
                          <b-input
                            v-model="formData.message"
                            class="has-fixed-size"
                            rows="4"
                            placeholder="Mensaje"
                            maxlength="500"
                            type="textarea"
                          ></b-input>
                        </b-field>
                      </form>
                      <b-button
                        class="no-border-button"
                        expanded
                        @click.prevent="sendForm()"
                        ><i class="fas fa-paper-plane"></i> Enviar
                        mensaje</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="contact_bg_second"></div>
          </div>
        </div>
      </section>
    </section>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>&copy; 2020 by Trend Studios for Bellanova</p>
      </div>
    </footer>

    <go-top :bottom="10" :size="50" bg-color="#ff9087" :radius="0"></go-top>
  </div>
</template>

<script>
import VuePlyr from "vue-plyr";
import axios from "axios";
import GoTop from "@inotom/vue-go-top";

export default {
  name: "bellanova",
  title: "Bellanova Studio",
  data: () => {
    return {
      showAbout: false,
      player: null,
      plyr: null,
      formData: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
    };
  },

  methods: {
    hideSection() {
      this.showAbout = false;
    },

    sendForm() {
      if (
        this.formData.name === null ||
        this.formData.name === "" ||
        this.formData.email === null ||
        this.formData.email === "" ||
        this.formData.subject === null ||
        this.formData.subject === "" ||
        this.formData.message === null ||
        this.formData.message === ""
      ) {
        this.$swal({
          icon: "warning",
          title: "¡Atención!",
          text: "Todos los campos son requeridos",
        });

        return;
      }

      axios
        .post("/api/contact.php", this.formData)
        .then((e) => {
          if (e.data.success) {
            this.formData.name = null;
            this.formData.email = null;
            this.formData.subject = null;
            this.formData.message = null;

            this.$swal({
              icon: "success",
              title: "¡Correcto!",
              text: "¡Mensaje enviado exitosamente!",
              timer: 8000,
            });
          } else {
            this.$swal({
              icon: "error",
              text: "No se ha podido enviar el mensaje. Intente mas tarde.",
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            text: "No se ha podido enviar el mensaje. Intente mas tarde.",
          });
        });
    },
  },

  components: {
    VuePlyr: VuePlyr,
    GoTop,
  },
  computed: {
    playerOptions() {
      return {
        playsinline: true,
        controls: ["play-large", "mute", "volume"],
      };
    },
  },

  mounted() {
    this.player = this.$refs.player.player;
    this.player.on("ready", () => {
      this.player.muted = false;
      this.player.autoplay = true;
      this.player.volume = 0;
    });

    this.plyr = this.$refs.plyr.player;
    this.plyr.on("ready", () => {
      this.plyr.muted = false;
      this.plyr.autoplay = true;
      this.plyr.volume = 0;
    });
  },
};
</script>

<style scoped>
#hero_bg {
  background-image: url("../assets/bellanovafinal.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  background-position: center center;
  background-color: #ffffff;
}
#img_bg {
  background-image: url("../assets/glamour_bellanova.webp");
  background-size: 400px 420px;
  background-repeat: no-repeat;
  height: 420px;
  background-position: center center;
  background-color: #ffffff;
}
#img_pool {
  background-image: url("../assets/bellanova_pool.webp");
  background-size: contain;
  background-repeat: no-repeat;
  height: 420px;
  background-position: center center;
  background-color: #e5c7b5;
}
#lollipop_bg {
  background-image: url("../assets/dulce_bellanova.webp");
  background-size: 330px 350px;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center center;
  background-color: #f0eded;
}
#lollipop_bg:hover {
  background-image: url("../assets/dulce_bellanova2.webp");
  transition: all 0.5s ease;
}

#face_bg {
  background-image: url("../assets/rostro.webp");
  background-size: 300px 300px;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center center;
  background-color: #fadcd6;
}

#face_bg:hover {
  background-image: url("../assets/rostro2.webp");
  transition: all 0.5s ease;
}

#coffee_bg {
  background-image: url("../assets/cafe_bellanova.webp");
  background-size: 300px 300px;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center center;
  background-color: #ff9087;
}
#coffee_bg:hover {
  background-image: url("../assets/cafe_bellanova2.webp");
  transition: all 0.5s ease;
}

.title {
  color: #363636;
  font-weight: 600;
  font-family: "Mr De Haviland", cursive;
  font-size: 80px;
}
#bg_bellanova {
  background-color: #dedada;
  background-size: cover;
  background-repeat: no-repeat;
  height: 420px;
  background-position: center center;
  padding: 6rem;
}

#video_bg {
  background-color: #dedada;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 400px;
}

video {
  display: block;
  margin: auto;
}

#contact_bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fadcd6;
  height: 300px;
}

#contact_bg_second {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  height: 463px;
}

#contact_video {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  height: 400px !important;
  width: auto !important;
}

.underline {
  border-bottom: 2px solid currentColor;
  display: inline-block;
  line-height: 0.85;
}

a {
  color: #363636;
}

video {
  object-fit: cover;
}

#promo_video {
  width: 400px;
  margin: 0 auto;
}

.border-top {
  border-top: 4px solid #ff9087;
}
.navbar {
  color: #858585;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: transparent;
  color: #ff9087;
  border-top: 2px solid #ff9087;
}

a.navbar-item {
  text-transform: uppercase;
  font-weight: bold;
}

.footer {
  background-color: #e8eff1;
  padding: 1rem;
  font-size: 1.1rem;
}

#about {
  background-color: #dedada;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 780px;
  margin-bottom: 10px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

#about_video {
  width: 672px;
  height: 377px;
  position: absolute;
  right: 50%;
}

#pink_slot {
  background-color: #fadcd6;
  min-height: 780px;
  max-width: 30%;
}

#about_text {
  max-width: 40%;
  min-height: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  margin-top: 60px;
}

.about_video_band {
  height: 100%;
  background-size: 672px 672px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fadcd6;
  z-index: 10000;
}
</style>