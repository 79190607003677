<template>
  <div id="bellanova-mobile">
    <nav class="level is-mobile pt-4 px-4">
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#hero_bg'">Inicio</a>
      </span>
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#about'">Nosotras</a>
      </span>
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#benefits'">Beneficios</a>
      </span>
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#contact'">Contacto</a>
      </span>
    </nav>

    <section class="hero border-top border-bottom" id="hero_bg"></section>
     <section>
       <vue-plyr ref="player" autoplay muted :options="playerOptions" class="shadow">
          <video  loop id="myVideo">
            <source src="../assets/herovideo.mp4" type="video/mp4" />
          </video>
        </vue-plyr>
    </section>


    <section id="about">
      <div class="columns">
        <div class="column">
          <div id="about_text" class="section">
            <h1 class="title">Nosotras</h1>
            <p class="has-text-justified">
              <span class="has-text-weight-bold">
                Bellanova es un Studio ubicado en el eje cafetero, cuenta con
                una de las mejores instalaciones e infraestructura en la ciudad
                de Armenia.
              </span>
              Nace como un proyecto enfocado a mujeres que desean ser
              profesionales en el mundo del modelaje webcam.
            </p>

            <p class="pt-4 has-text-justified">
              Se destaca la feminidad, delicadeza y sensualidad de cada una de
              sus integrantes, respetando su estilo a través de un trabajo en
              equipo con el que logra fomentar lo mejor de cada quien. Para ello
              cuenta con un grupo de profesionales experimentados que ayudan
              como medio para alcanzar los objetivos que la modelo se haya
              propuesto.
            </p>
          </div>
        </div>
        
        <div class="column p-0 is-mobile is-gapless is-vcentered is-centered">
          <vue-plyr ref="plyr" :options="playerOptions" class="player">
            <video src="../assets/espacio_bellanova.mp4">
              <source src="../assets/espacio_bellanova.mp4" type="video/mp4" />
            </video>
          </vue-plyr>
        </div>
      </div>
    </section>

    <section id="benefits">
      <div class="columns is-mobile is-gapless is-vcentered is-centered">
        <div class="column">
          <div id="glamour"></div>
        </div>
        <div class="column">
          <div id="pool"></div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="section">
            <h1 class="title underline has-text-centered">Beneficios</h1>
            <p class="has-text-justified pt-4">
              En el momento en que inicias a formar parte de Bellanova cuentas
              no solo con una nueva familia que te acoge y te brinda todo el
              apoyo emocional que necesitas, sino que también te hace partícipe
              del crecimiento de la empresa como tal, por ende te ofrece
              beneficios.
            </p>
          </div>
        </div>
      </div>
      <div
        class="columns is-mobile is-gapless is-multiline is-vcentered is-centered"
      >
        <div class="column is-12">
          <vue-plyr ref="plyr" :options="playerOptions" class="player">
            <video width="400" height="475" src="../assets/bellanova_promo.mp4">
              <source src="../assets/bellanova_promo.mp4" type="video/mp4" />
            </video>
          </vue-plyr>
        </div>
        <div class="column is-6">
          <div id="lollipop_bg"></div>
        </div>
        <div class="column is-6">
          <div id="coffee_bg"></div>
        </div>
      </div>
      <div class="column is-12">
        <h1 class="title underline has-text-centered">Acompañamiento</h1>
        <div class="content">
          <ul>
            <li>Ayuda en la creación y acreditación de tu performance.</li>
            <li>Sesiones de maquillaje, fotografía y expresión corporal.</li>
            <li>Equipo de profesionales que te ayudan en cada área..</li>
            <li>Capacitaciones que ayudan a mejorar tu rendimiento.</li>
            <li>
              Apoyo y acompañamiento del único Studio VIP del Eje cafetero TREND
              STUDIOS.
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section id="insta_feed" class="hero pt-5">
      <div class="columns is-mobile is-vcentered is-centered is-gapless">
        <div class="column has-text-centered">
          <iframe
            src="https://snapwidget.com/embed/889987"
            class="snapwidget-widget"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            style="border: none; overflow: hidden; width: 100%; height: 230px"
          ></iframe>
          <b-button
            tag="a"
            href="https://www.instagram.com/bellanova_studios/"
            target="_blank"
          >
            <i class="fas fa-external-link-alt"></i> Mostrar más
          </b-button>
        </div>
      </div>
    </section>

    <div id="contact" class="mt-6">
      <div class="columns is-multiline has-text-centered">
        <div class="column is-12 pt-2">
          <h1 class="title underline m-3">Contacto</h1>
          <div class="column">
            <span id="social_media">
              <a href="https://www.instagram.com/bellanova_studios/">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="https://www.facebook.com/studiosbellanova" class="px-5">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/bellanovaglam">
                <i class="fab fa-twitter"></i>
              </a>
            </span>
          </div>

          <div class="column">
            <a href="mailto:contacto@estudiobellanova.com" id="email"
              >contacto@estudiobellanova.com</a
            ><br />
            <a href="tel:313 6183662" id="phone">Tel: 313 6183662</a><br />
            <span>Armenia - Quindío</span> <br />
            <p>
              Abrimos de Lunes a Sábado
              <br />
              jornadas en la mañana, tarde y noche.
            </p>
          </div>

          <div class="column">
            <b-button
              v-scroll-to="'#contact_form'"
              class="no-border-button"
              @click="showContactForm = !showContactForm"
              >Contactanos</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <transition name="slide">
      <div id="contact_form" class="section pt-6" v-if="showContactForm">
        <form>
          <b-field grouped>
            <b-field expanded>
              <b-input placeholder="Nombre"></b-input>
            </b-field>
            <b-field expanded>
              <b-input placeholder="Email"></b-input>
            </b-field>
          </b-field>
          <b-field>
            <b-input placeholder="Asunto"></b-input>
          </b-field>
          <b-field>
            <b-input
              class="has-fixed-size"
              rows="4"
              placeholder="Mensaje"
              maxlength="500"
              type="textarea"
            ></b-input>
          </b-field>
        </form>
        <b-button class="no-border-button" expanded @click.prevent="sendForm()"
          ><i class="fas fa-paper-plane"></i> Enviar mensaje</b-button
        >
      </div>
    </transition>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>&copy; 2020 by Trend Studios for Bellanova</p>
      </div>
    </footer>
    <go-top :bottom="10" :size="50" bg-color="#ff9087" :radius="0"></go-top>
  </div>
</template>

<script>
import GoTop from "@inotom/vue-go-top";
import VuePlyr from "vue-plyr";
import axios from "axios";

export default {
  name: "HomeMobile",
  title: "Bellanova Studio",
  data: () => {
    return {
      showContactForm: false,
      formData: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
    };
  },

  components: {
    VuePlyr,
    GoTop,
  },

  methods: {
    sendForm() {
      if (
        this.formData.name === null ||
        this.formData.name === "" ||
        this.formData.email === null ||
        this.formData.email === "" ||
        this.formData.subject === null ||
        this.formData.subject === "" ||
        this.formData.message === null ||
        this.formData.message === ""
      ) {
        this.$swal({
          icon: "warning",
          title: "¡Atención!",
          text: "Todos los campos son requeridos",
        });

        return;
      }

      axios
        .post("/api/contact.php", this.formData)
        .then((e) => {
          if (e.data.success) {
            this.formData.name = null;
            this.formData.email = null;
            this.formData.subject = null;
            this.formData.message = null;
            this.showContactForm = false;
            this.$swal({
              icon: "success",
              title: "¡Correcto!",
              text: "¡Mensaje enviado exitosamente!",
              timer: 8000,
            });
          } else {
            this.$swal({
              icon: "error",
              text: "No se ha podido enviar el mensaje. Intente mas tarde.",
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            text: "No se ha podido enviar el mensaje. Intente mas tarde.",
          });
        });
    },
  },

  computed: {
    playerOptions() {
      return {
        playsinline: true,
        volume: 20,
        controls: ["play-large"],
        debug: false,
      };
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 4px solid #ff9087;
}

.border-bottom {
  border-top: 4px solid #ff9087;
}

#hero_bg {
  background-image: url("../assets/bellanovafinal.svg");
  background-size: 380px 500px;
  background-repeat: no-repeat;
  height: 100px;
  background-position: center center;
  background-color: #fff;
}

#about {
  background-color: #dedada;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}
#pool {
  background-image: url("../assets/bellanova_pool.webp");
  background-size: contain;
  background-repeat: no-repeat;
  height: 250px;
  background-position: center center;
  background-color: #e5c7b5;
}
#glamour {
  background-image: url("../assets/glamour_bellanova.webp");
  background-size: contain;
  background-repeat: no-repeat;
  height: 250px;
  background-position: center center;
  background-color: #fff7ee;
}

#lollipop_bg {
  background-image: url("../assets/dulce_bellanova.webp");
  background-size: 160px 160px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  background-color: #f0eded;
}

#coffee_bg {
  background-image: url("../assets/cafe_bellanova.webp");
  background-size: 160px 160px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  background-color: #ff9087;
}

#contact {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fadcd6;
  height: 100%;
}

.footer {
  background-color: #e8eff1;
  padding: 1rem;
  font-size: 1.1rem;
}

.underline {
  border-bottom: 2px solid currentColor;
  display: inline-block;
  line-height: 0.85;
  text-shadow: 2px 2px #fff, 2px -2px #fff, -2px 2px #fff, -2px -2px #fff;
}

.title {
  color: #363636;
  font-weight: 600;
  font-family: "Mr De Haviland", cursive;
  font-size: 60px;
}

.title:not(:last-child) {
  margin-bottom: 0px;
}

.section {
  padding: 1rem 1.5rem;
}

p {
  font-size: 12px;
}
ul > li {
  font-size: 12px;
}
video {
  object-fit: cover;
  height: 100%;
}
a {
  color: #363636;
}
</style>